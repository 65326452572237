import * as React from "react"
import classNames from "classnames"

const styles = {
  maxWidth: '1400px',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: '2rem',
  paddingRight: '2rem'
}

const Container = ({children, className = ""}) => {
  return (
    <div className={classNames("container", className)} style={styles}>      
        {children}
    </div>
  )
}

export default Container 