import * as React from "react"
import { Link } from "gatsby"


import Page from './../components/Page'
import Container from "../components/Container";

const metaDescription = " 404 - Page not found"
// markup
const NotFoundPage = () => {
  return (
    <Page className="page-404" metaTitle="Loopr AI | Page Not Found" metaDescription={metaDescription}> 
    <section>
      <Container>
        <div className="title">
        <b>Error</b>
        <h2><strong>404</strong></h2>
        </div>
        <p>Page Not Found</p>
        <Link to="/" className="btn btn-outline">
          <span>
            Home Page
          </span>
        </Link>
        
      </Container>  
      </section>     
    </Page>
  )
}

export default NotFoundPage
